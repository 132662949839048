export default {
  data: function () {
    return {};
  },
  on: {
    pageAfterin: function () {
      let selectedDeviceId;
      const codeReader = new ZXing.BrowserMultiFormatReader();
      codeReader.getVideoInputDevices().then(videoInputDevices => {
        const sourceSelect = document.getElementById('sourceSelect');
        selectedDeviceId = videoInputDevices[0].deviceId;
        if (videoInputDevices.length >= 1) {
          videoInputDevices.forEach(element => {
            const sourceOption = document.createElement('option');
            sourceOption.text = element.label;
            sourceOption.value = element.deviceId;
            sourceSelect.appendChild(sourceOption);
          });
          sourceSelect.onchange = () => {
            selectedDeviceId = sourceSelect.value;
          };
          const sourceSelectPanel = document.getElementById('sourceSelectPanel');
          sourceSelectPanel.style.display = 'block';
        }
        document.getElementById('startButton').addEventListener('click', () => {
          codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
            if (result) {
              document.getElementById('result').textContent = result.text;
              codeReader.reset();
            }
            if (err && !(err instanceof ZXing.NotFoundException)) {
              document.getElementById('result').textContent = err;
            }
          });
        });
        document.getElementById('resetButton').addEventListener('click', () => {
          codeReader.reset();
          document.getElementById('result').textContent = '';
        });
      });
    }
  },
  id: '699f1beebc',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a class=link href=/ ><i class="icon icon-back"></i> <span>Back</span></a></div><div class=title>Scanner</div><div class=right></div></div></div><div class=page-content><div><a class=button id=startButton>Start</a> <a class=button id=resetButton>Reset</a></div><div><video id=video width=300 height=200 style="border: 1px solid gray; width: 100vw; height: 50vh"></video></div><div id=sourceSelectPanel style=display:none><label for=sourceSelect>Change video source:</label> <select id=sourceSelect style=max-width:400px></select></div><label>Result:</label><pre><code id=result></code></pre></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};