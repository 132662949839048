
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      var FileSaver = require('file-saver');
export default {
  data: function () {
    return {
      ls: null,
      nav: navigator
    };
  },
  methods: {
    go_back() {
      if (this.$f7.views.current.id == "view_pl") {
        // Polter view
        this.$f7.views.current.router.navigate('/', {
          force: true
        });
        this.$f7.views.ls.router.navigate('/lieferscheine/', {
          force: true,
          ignoreCache: true,
          reloadCurrent: true
        });
      } else {
        this.$f7.views.current.router.navigate('/lieferscheine/', {
          force: true,
          ignoreCache: true,
          reloadCurrent: true
        });
      }
    },
    create_pdf() {
      var self = this;
      var printSettings = {
        width: 63,
        height: 40,
        margin: 5
      };
      var position = {
        x: printSettings.margin,
        y: printSettings.margin
      };
      var doc = new jsPDF({
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait'
      });
      doc.setFont('helvetica');
      doc.setFontSize(12);
      doc.text("Lieferschein", position.x + 5, position.y + 10);
      doc.setFontSize(10);
      if (self.ls.doppel == true) {
        doc.text("Doppellieferung", position.x + 5, position.y + 15);
      }
      var d = new Date(self.ls.lsid * 1000);
      var datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
      doc.text('Nummer:', position.x + 5, position.y + 25);
      doc.text('' + self.ls.lsid + ' / ' + datestring, position.x + 40, position.y + 25);
      doc.text('Heizung:', position.x + 5, position.y + 30);
      doc.text('' + self.ls.kundtext + ' (' + self.ls.kund + ')', position.x + 40, position.y + 30);
      doc.text('Hacker:', position.x + 5, position.y + 35);
      doc.text('' + self.ls.hackertext + ' (' + self.ls.hacker + ')', position.x + 40, position.y + 35);
      doc.text('Transporteur:', position.x + 5, position.y + 40);
      doc.text('' + self.ls.transtext + ' (' + self.ls.trans + ')', position.x + 40, position.y + 40);
      doc.text('Sortiment:', position.x + 5, position.y + 45);
      doc.text('' + self.ls.sorttext + ' (' + self.ls.sort + ')', position.x + 40, position.y + 45);
      doc.text('Lieferant:', position.x + 5, position.y + 50);
      doc.text('' + self.ls.lieftext + ' (' + self.ls.lief + ')', position.x + 40, position.y + 50);
      doc.text('Polter/Los:', position.x + 5, position.y + 55);
      doc.text('' + self.ls.polter + '/' + self.ls.los, position.x + 40, position.y + 55);
      doc.text('Zu-/Abschlag:', position.x + 5, position.y + 60);
      doc.text('' + self.ls.zuab, position.x + 40, position.y + 60);
      doc.text('Abgeschlossen:', position.x + 5, position.y + 65);
      doc.text('' + self.ls.fertig, position.x + 40, position.y + 65);
      doc.text('Menge:', position.x + 5, position.y + 70);
      doc.text('' + self.ls.menge, position.x + 40, position.y + 70);
      doc.text('Kommentar:', position.x + 5, position.y + 75);
      doc.text('' + self.ls.kommentar, position.x + 40, position.y + 75);
      var pos2 = 0;
      if (self.ls.doppel == true) {
        doc.text("------------------------------------------------------------------------------", position.x + 5, position.y + 80);
        doc.text('Lieferant:', position.x + 5, position.y + 85);
        doc.text('' + self.ls.polter2.lieftext + ' (' + self.ls.polter2.lief + ')', position.x + 40, position.y + 85);
        doc.text('Polter/Los:', position.x + 5, position.y + 90);
        doc.text('' + self.ls.polter2.polter + '/' + self.ls.polter2.los, position.x + 40, position.y + 90);
        doc.text('Zu-/Abschlag:', position.x + 5, position.y + 95);
        doc.text('' + self.ls.polter2.zuab, position.x + 40, position.y + 95);
        doc.text('Abgeschlossen:', position.x + 5, position.y + 100);
        doc.text('' + self.ls.polter2.fertig, position.x + 40, position.y + 100);
        doc.text('Menge:', position.x + 5, position.y + 105);
        doc.text('' + self.ls.polter2.menge, position.x + 40, position.y + 105);
        pos2 = 30;
      }

      /*
      var imgData = self.$$("#qrcode > img").prop('currentSrc');
      if (imgData)
        doc.addImage(imgData, 'PNG', position.x+140, position.y+20, 50, 50);
      */

      var qrcodeid = document.getElementById("qrcode");
      var imgData = qrcodeid.getElementsByTagName("canvas")[0].toDataURL("image/png");
      if (imgData) doc.addImage(imgData, 'PNG', position.x + 140, position.y + 20, 50, 50);

      //doc.save('LS'+self.ls.lsid+'.pdf');
      var blob = doc.output('blob');
      FileSaver.saveAs(blob, 'LS' + self.ls.lsid + '.pdf');
    },
    connect_printer() {
      var self = this;
      if (self.printCharacteristic == null) {
        self.nav.bluetooth.requestDevice({
          filters: [{
            services: ['000018f0-0000-1000-8000-00805f9b34fb']
          }]
        }).then(device => {
          return device.gatt.connect();
        }).then(server => server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")).then(service => service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb")).then(characteristic => {
          self.printCharacteristic = characteristic;
          self.sendPrinterData();
        });
      } else {
        self.sendPrinterData();
      }
    },
    sendPrinterData(self) {
      var index = 0;
      var encoder;
      var buffer;
      var self = this;
      init();
      reset();
      addtext('\x1b\x74\x00');
      dblh();
      addtext("Lieferschein");
      if (self.ls.doppel == true) {
        normal();
        nl();
        addtext('Doppellieferung');
      }
      nl();
      nl();
      normal();
      bold();
      addtext('Nummer:       ');
      boldoff();
      addtext(self.ls.lsid);
      nl();
      bold();
      addtext('Heizung:      ');
      boldoff();
      nl();
      addtext(' ' + self.ls.kundtext);
      addtext(' (' + self.ls.kund + ')');
      nl();
      bold();
      addtext('Hacker:       ');
      nl();
      boldoff();
      addtext(' ' + self.ls.hackertext);
      addtext(' (' + self.ls.hacker + ')');
      nl();
      bold();
      addtext('Transporteur: ');
      boldoff();
      nl();
      addtext(' ' + self.ls.transtext);
      addtext(' (' + self.ls.trans + ')');
      nl();
      bold();
      addtext('Sortiment:    ');
      boldoff();
      nl();
      addtext(' ' + self.ls.sorttext);
      addtext(' (' + self.ls.sort + ')');
      nl();
      bold();
      addtext('Lieferant:    ');
      boldoff();
      nl();
      addtext(' ' + self.ls.lieftext);
      addtext(' (' + self.ls.lief + ')');
      nl();
      bold();
      addtext('Polter/Los:   ');
      boldoff();
      addtext(self.ls.polter + '/' + self.ls.los);
      nl();
      bold();
      addtext('Zu-/Abschlag: ');
      boldoff();
      addtext(self.ls.zuab);
      nl();
      bold();
      addtext('Abgeschl.:    ');
      boldoff();
      addtext(self.ls.fertig);
      nl();
      bold();
      addtext('Menge:        ');
      boldoff();
      addtext(self.ls.menge);
      nl();

      // Doppellieferung

      if (self.ls.doppel == true) {
        addtext('--------------------------------');
        nl();
        bold();
        addtext('Lieferant:    ');
        boldoff();
        addtext(' ' + self.ls.polter2.lieftext);
        addtext(' (' + self.ls.polter2.lief + ')');
        nl();
        bold();
        addtext('Polter/Los:   ');
        boldoff();
        addtext(self.ls.polter2.polter + '/' + self.ls.polter2.los);
        nl();
        bold();
        addtext('Abgeschl.:    ');
        boldoff();
        addtext(self.ls.polter2.fertig);
        nl();
        bold();
        addtext('Zu-/Abschlag: ');
        boldoff();
        addtext(self.ls.polter2.zuab);
        nl();
        bold();
        addtext('Menge:        ');
        boldoff();
        addtext(self.ls.polter2.menge);
        nl();
      }
      bold();
      addtext('Kommentar:        ');
      boldoff();
      nl();
      addtext(self.ls.kommentar);
      nl();
      nl();
      addtext(getdate());
      nl();
      aligncenter();
      var qrtext = self.ls.lief + ';' + self.ls.hacker + ';' + self.ls.trans + ';' + self.ls.kund + ';' + self.ls.sort + ';' + self.ls.polter + ';' + self.ls.zuab + ';' + self.ls.fertig + ';' + self.ls.los + ';' + self.ls.menge;
      if (self.ls.doppel == true) {
        qrtext = qrtext + ':' + self.ls.polter2.lief + ';' + self.ls.hacker + ';' + self.ls.trans + ';' + self.ls.kund + ';' + self.ls.polter2.sort + ';' + self.ls.polter2.polter + ';' + self.ls.polter2.zuab + ';' + self.ls.polter2.fertig + ';' + self.ls.polter2.los + ';' + self.ls.polter2.menge;
      }
      if (parseInt(self.ls.kund) > 2) {
        // Alles was nicht HKW I und II ist, füge den Lieferschein am Ende hinzu
        qrtext = qrtext + '$$' + self.ls.lsid;
      }
      noqs_qrcode(qrtext);
      nl();
      nl();
      cut();
      reset();
      writebufferlong();
      function init() {
        encoder = new TextEncoder("utf-8");
        buffer = new Uint8Array('\x1b\x40');
      }
      function addtext(textinput) {
        var text = encoder.encode(textinput);
        buffer = concatTypedArrays(buffer, text);
      }
      function writebufferlong() {
        // Can only write 8 bytes at a time to the characteristic
        // Need to send the image data in 512 byte batches
        if (index + 8 < buffer.length) {
          self.printCharacteristic.writeValue(buffer.slice(index, index + 8)).then(() => {
            index += 8;
            writebufferlong();
          }).catch(error => alert(error));
        } else {
          // Send the last bytes
          if (index < buffer.length) {
            self.printCharacteristic.writeValue(buffer.slice(index, buffer.length)).then(() => {
              //resolve();
            }).catch(error => alert(error));
          } else {
            //resolve();
          }
        }
      }

      // Functions to add bytes to array
      function concatTypedArrays(a, b) {
        // a, b TypedArray of same type
        var c = new a.constructor(a.length + b.length);
        c.set(a, 0);
        c.set(b, a.length);
        return c;
      }
      function addbyte(byte) {
        buffer = concatBytes(buffer, byte);
      }
      function getInt64Bytes(x) {
        var bytes = [];
        var i = 1;
        do {
          bytes[--i] = x & 255;
          x = x >> 8;
        } while (i);
        return bytes;
      }
      function concatBytes(ui8a, byte) {
        var b = new Uint8Array(1);
        b[0] = byte;
        return concatTypedArrays(ui8a, b);
      }

      // Printer functions
      function reset() {
        addtext('\x1b\x40');
      }
      function dblh() {
        addtext('\x1b\x21\x30');
      }
      function nl() {
        addtext('\n');
      }
      function normal() {
        addtext('\x1b\x21\x00');
      }
      function bold() {
        addtext('\x1b\x45\x01');
      }
      function boldoff() {
        addtext('\x1b\x45\x00');
      }
      function aligncenter() {
        addtext('\x1b\x61\x01');
      }
      function cut() {
        addtext('\x1d\x56\x00');
      }
      function getdate() {
        var d = new Date();
        var tag = d.getDate();
        var monat = d.getMonth() + 1;
        var jahr = d.getFullYear();
        var stunde = d.getHours();
        var minute = d.getMinutes();
        var sekunde = d.getSeconds();
        return tag + "." + monat + "." + jahr + " " + stunde + ":" + minute + ":" + sekunde;
      }
      function noqs_qrcode(text) {
        const qrLen = text.length + 3;
        const size1 = toHex(qrLen % 256);
        const size0 = toHex(Math.floor(qrLen / 256));
        addtext('\x0a');
        addtext('\x1d\x28\x6b\x04\x00\x31\x41\x31\x00'); // Model (x31/x32/x33)
        addtext('\x1d\x28\x6b\x03\x00\x31\x43\x06'); // Module ( Size) 1-8
        addtext('\x1d\x28\x6b\x03\x00\x31\x45\x33'); // correction x30 -> 7% x31-> 15% x32 -> 25% x33 -> 30%]
        //addtext('\x1d\x28\x6b'+size1+size0+'\x31\x50\x30'); // Store QR
        addtext('\x1d\x28\x6b');
        addbyte(getInt64Bytes(qrLen % 256));
        addbyte(getInt64Bytes(Math.floor(qrLen / 256)));
        addtext('\x31\x50\x30');
        addtext(text);
        addtext('\x1d\x28\x6b\x03\x00\x31\x51\x30');
      }
      function toHex(number) {
        var hex = Math.abs(number).toString(16);
        while (hex.length < 2) {
          hex = "0" + hex;
        }
        return "x" + hex.substring(0, 2).toUpperCase();
      }
    }
  },
  on: {
    pageInit: function () {
      var sort = [];
      var self = this;
      var db = self.$f7.data.db;
      var app = self.$f7;

      // Lieferschein laden
      var searchls = db.transaction("my_lieferschein").objectStore("my_lieferschein").get(parseInt(self.$f7route.params.ls_id)).onsuccess = function (e) {
        var ls = e.target.result;
        if (ls.fertig == "") {
          ls.fertig = "0";
        }
        if (ls.polter == "") {
          ls.polter = "0";
        }
        self.ls = ls;
        /*
          Pos Feld  Typ Beispiel
          1 Lieferant numerisch 13026
          2 Hacker  numerisch 07823
          3 Transporteur  numerisch 10151
          4 Werk  numerisch 1
          5 HolzArt alphanumerisch  A003
          6 PolterNr  alphanumerisch  6806410
          7 PolterArt PG1, PG2 oder PG3 PG2
          8 PolterAbgeschlossen 1 = Ja   / 0 = Nein 0
          9 LosNr alphanumerisch  112WHS1516
          10  RaumMeter numerisch 50.2
        */

        self.$setState({
          ls: ls
        }, () => {
          var qrtext = ls.lief + ';' + ls.hacker + ';' + ls.trans + ';' + ls.kund + ';' + ls.sort + ';' + ls.polter + ';' + ls.zuab + ';' + ls.fertig + ';' + ls.los + ';' + ls.menge;
          if (ls.doppel == true) {
            qrtext = qrtext + ':' + ls.polter2.lief + ';' + ls.hacker + ';' + ls.trans + ';' + ls.kund + ';' + ls.polter2.sort + ';' + ls.polter2.polter + ';' + ls.polter2.zuab + ';' + ls.polter2.fertig + ';' + ls.polter2.los + ';' + ls.polter2.menge;
          }
          if (parseInt(ls.kund) > 2) {
            // Alles was nicht HKW I und II ist, füge den Lieferschein am Ende hinzu
            qrtext = qrtext + '\u0024\u0024' + ls.lsid;
          }
          var qrcode = new QRCode("qrcode", {
            text: qrtext,
            width: 256,
            height: 256,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });
        });
      };
    },
    pageAfterIn: function () {},
    pageAfterOut: function () {
      // page has left the view
    }
  },
  id: '8b34e11fc2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# @click=go_back()><i class="icon icon-back"></i> <span class=if-not-md>zurück</span></a></div><div class=title>Lieferschein</div></div></div><div class=page-content><div class=block-title>Lieferschein</div><div class="block block-strong"><div class=data-table id=tableexport><table><tbody>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.ls, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr><th class=label-cell>Nummer</th><td class=label-cell>';
          r += c(ctx_2.ls.lsid, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Polter/Los</td><td class=label-cell>';
          r += c(ctx_2.ls.polter, ctx_2);
          r += '/';
          r += c(ctx_2.ls.los, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Fertig</td><td class=label-cell>';
          r += c(ctx_2.ls.fertig, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Lieferant</td><td class=label-cell>';
          r += c(ctx_2.ls.lieftext, ctx_2);
          r += ' ';
          r += c(ctx_2.ls.lief, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Sortiment</td><td class=label-cell>';
          r += c(ctx_2.ls.sorttext, ctx_2);
          r += ' ';
          r += c(ctx_2.ls.sort, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Zu/Abschlag</td><td class=label-cell>';
          r += c(ctx_2.ls.zuab, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Heizung</td><td class=label-cell>';
          r += c(ctx_2.ls.kundtext, ctx_2);
          r += ' ';
          r += c(ctx_2.ls.kund, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Transporteur</td><td class=label-cell>';
          r += c(ctx_2.ls.transtext, ctx_2);
          r += ' ';
          r += c(ctx_2.ls.trans, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Hacker</td><td class=label-cell>';
          r += c(ctx_2.ls.hackertext, ctx_2);
          r += ' ';
          r += c(ctx_2.ls.hacker, ctx_2);
          r += '</td></tr><tr><td class=label-cell>Menge</td><td class=label-cell>';
          r += c(ctx_2.ls.menge, ctx_2);
          r += ' srm</td></tr>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.ls.doppel, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<tr><th class=label-cell>Doppellieferung</th><td class=label-cell>&nbsp;</td></tr><tr><td class=label-cell>Polter/Los</td><td class=label-cell>';
              r += c(ctx_3.ls.polter2.polter, ctx_3);
              r += '/';
              r += c(ctx_3.ls.los, ctx_3);
              r += '</td></tr><tr><td class=label-cell>Fertig</td><td class=label-cell>';
              r += c(ctx_3.ls.polter2.fertig, ctx_3);
              r += '</td></tr><tr><td class=label-cell>Lieferant</td><td class=label-cell>';
              r += c(ctx_3.ls.polter2.lieftext, ctx_3);
              r += ' ';
              r += c(ctx_3.ls.polter2.lief, ctx_3);
              r += '</td></tr><tr><td class=label-cell>Zu/Abschlag</td><td class=label-cell>';
              r += c(ctx_3.ls.polter2.zuab, ctx_3);
              r += '</td></tr><tr><td class=label-cell>Menge</td><td class=label-cell>';
              r += c(ctx_3.ls.polter2.menge, ctx_3);
              r += ' srm</td></tr>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<tr><th class=label-cell>Kommentar</th><td class=label-cell>';
          r += c(ctx_2.ls.kommentar, ctx_2);
          r += '</td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<tr><td colspan=2><br><div id=qrcode ref=qr></div></td></tr></tbody></table></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.ls, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="block block-strong row"><div class=col><a class="button button-fill convert-form-to-data" href=# @click=connect_printer()>Lieferschein drucken</a></div><div class=col><a class="button button-fill convert-form-to-data" href=# @click=create_pdf()>PDF generieren</a></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    