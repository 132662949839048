
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      xpolver: null,
      ha: {
        haName: 'Hacker'
      },
      sorti: null,
      sortiw: [],
      sorti2: [],
      lief: null
    };
  },
  methods: {
    changepolter: function () {
      var db = this.$f7.data.db;
      var trx = db.transaction("t_polter");
      var objectStore = trx.objectStore("t_polter");
      var self = this;
      var app = this.$f7;
      var polterid = self.$$("#polter")[0].value;
      //34962

      if (polterid > 0) {
        var searchpolter = objectStore.index("polterID").get(parseInt(polterid));
        searchpolter.onsuccess = function (event) {
          if (typeof event.target.result !== "undefined") {
            //suche Besitzer
            var searchbesitzer = db.transaction("t_lieferant").objectStore("t_lieferant").index("LiBesitzer").get(String(event.target.result.ownerCode)).onsuccess = function (e) {
              self.$setState({}, () => {
                if (typeof e.target.result !== "undefined") {
                  app.smartSelect.get('#smartbesitzer').setValue(String(e.target.result.LiNummer));
                }
              });
              self.$setState({
                sorti: self.sorti2
              }, () => {
                app.smartSelect.get('#smartsortiment').setValue(String("A001"));
              });
            };

            // Setze Zu/Abschlag
            if (event.target.result.addInfo2) {
              var pgs = ["1", "2", "3"];
              var pg = event.target.result.addInfo2;
              if (pgs.includes(pg)) {
                self.$setState({}, () => {
                  self.$$('#zuabsearch').val('PG' + pg);
                });
              }
            }

            // Setze Los
            self.$setState({}, () => {
              self.$$('#los').val(event.target.result.ticketID); //.value("event.target.result.ticketID");
            });

            // Setze Sortiment
            if (event.target.result.addInfo1) {
              var sort = event.target.result.addInfo1;
              var searchsort = db.transaction("t_sortiment").objectStore("t_sortiment").get(String(sort)).onsuccess = function (es) {
                if (typeof es.target.result !== "undefined") {
                  self.$setState({}, () => {
                    app.smartSelect.get('#smartsortiment').setValue(es.target.result.SoCode);
                  });
                }
              };
            }
            self.$setState({
              xpolver: event.target.result
            });
          } else {
            console.info("Polter nicht gefunden");
            self.$setState({
              xpolver: null,
              sorti: self.sortiw
            }, () => {
              app.smartSelect.get('#smartsortiment').setValue(String("A001"));
            });
          }
        };
      } else {
        self.$setState({
          xpolver: null
        });
      }
    },
    savepolter: function () {
      var app = this.$f7;
      var db = this.$f7.data.db;
      var formData = app.form.convertToData('#polterform');
      formData.lieftext = app.smartSelect.get('#smartbesitzer').valueEl.innerText;
      formData.sorttext = app.smartSelect.get('#smartsortiment').valueEl.innerText;
      var request = db.transaction('my_polter', "readwrite").objectStore("my_polter").add(formData);
      app.views.main.router.navigate('/');
    }
  },
  on: {
    pageInit: function () {
      var sorti = [];
      var lief = [];
      var self = this;
      var db;
      var self = this;
      db = self.$f7.data.db;

      // Sortiment laden
      var trx = db.transaction(['t_sortiment', 't_lieferant']);
      var objectStore = trx.objectStore("t_sortiment").index('SoName');
      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          sorti.push(cursor.value);
          if (cursor.value.SoGruppe == "Waldholz") {
            self.sorti2.push(cursor.value);
          }
          cursor.continue();
        }
        self.$update();
      };

      // Lieferanten laden
      var objectStore = trx.objectStore("t_lieferant").index('LiName');
      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          lief.push(cursor.value);
          cursor.continue();
        }
      };
      trx.oncomplete = function () {
        self.$setState({
          lief: lief,
          sorti: sorti,
          sortiw: sorti
        }, () => {
          self.$f7.smartSelect.get('#smartbesitzer').setValue(String(lief[0].LiNummer));
          self.$f7.smartSelect.get('#smartsortiment').setValue(String(sorti[0].SoCode));
        });
      };
    },
    pageAfterIn: function () {},
    pageAfterOut: function () {
      // page has left the view
    }
  },
  id: '9935c8fedf',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Zurück</span></a></div><div class=title>Polter hinzufügen</div></div></div><div class=page-content><div class=block><form class=list id=polterform><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Polter</div><div class=item-input-wrap><input @change=changepolter type=number name=polter id=polter placeholder=Polter> <span class=input-clear-button></span></div></div></div></li>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.xpolver, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">xPolver</div><div class=item-input-wrap>Besitzer Code: ';
          r += c(ctx_2.xpolver.ownerCode, ctx_2);
          r += '<br>Besitzer Info: ';
          r += c(ctx_2.xpolver.ownerInfo, ctx_2);
          r += '<br>Los: ';
          r += c(ctx_2.xpolver.ticketID, ctx_2);
          r += '<br>Polter Info: ';
          r += c(ctx_2.xpolver.polterInfo, ctx_2);
          r += '<br>Polter Grösse: ';
          r += c(ctx_2.xpolver.size, ctx_2);
          r += '<br>Sortiment: ';
          r += c(ctx_2.xpolver.sortCode, ctx_2);
          r += '</div></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Los</div><div class=item-input-wrap><input type=text id=los name=los placeholder=Los></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Zu-/Abschlag</div><div class=item-input-wrap><select name=zuab id=zuabsearch><option value=PG1>Normales Polter (PG1)</option><option value=PG2>Polter mit Zuschlag (PG2)</option><option value=PG3>Polter mit Abschlag (PG3)</option></select></div></div></div></li><!--<li>\r\n              <div class="item-content item-input">\r\n                <div class="item-inner">\r\n                  <div class="item-title item-label">Sortiment</div>\r\n                  <div class="item-input-wrap">\r\n                    <select name="sort" id="sortsearch">\r\n                      ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.sort, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                        <option value="';
          r += c(ctx_2.SoCode, ctx_2);
          r += '">';
          r += c(ctx_2.SoName, ctx_2);
          r += '</option>\r\n                      ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                    </select>\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </li>\r\n            --><li><a id=smartsortiment class="item-link smart-select smart-select-init" data-close-on-select=“true”><select name=sort id=sortsearch>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.sorti, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<option value=';
          r += c(ctx_2.SoCode, ctx_2);
          r += '>';
          r += c(ctx_2.SoName, ctx_2);
          r += '</option>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</select><div class=item-content><div class=item-inner><div class=item-title>Sortiment</div></div></div></a></li><li><a id=smartbesitzer class="item-link smart-select smart-select-init" data-close-on-select=“true”><select name=lief id=liefsearch>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.lief, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<option value=';
          r += c(ctx_2.LiNummer, ctx_2);
          r += '>';
          r += c(ctx_2.LiName, ctx_2);
          r += ' (';
          r += c(ctx_2.LiBesitzer, ctx_2);
          r += ')</option>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</select><div class=item-content><div class=item-inner><div class=item-title>Lieferant</div></div></div></a></li><!--<li>\r\n              <div class="item-content">\r\n                <div class="item-inner">\r\n                  <div class="item-title">Toggle</div>\r\n                  <div class="item-after">\r\n                    <label class="toggle toggle-init">\r\n                      <input type="checkbox" name="toggle" value="yes"><i class="toggle-icon"></i>\r\n                    </label>\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </li> --></ul></form><div class="block block-strong row"><div class=col><a class="button button-fill convert-form-to-data" href=# @click=savepolter>Polter speichern</a></div></div><br><br></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    