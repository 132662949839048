export default {
  id: '2e78722478',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=about><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>About</div></div></div><div class=page-content><!-- Static Navbar --><div class=block-title>Über die Applikation</div><div class="block block-strong"><p>Diese Applikation beschränkt sich auf die Erstellung von Lieferscheinen für Werke, welche von der Raurica Gruppe betreut werden. Der Ausdruck der erstellten Belege kann nur mit Quittungsdruckern (ESC/POS) durchgeführt werden.</p></div><div class=block-title>Kontakt</div><div class=block><p>Bei Fragen zur Lieferung von Energieholz, Abrechnungen und Dispo wende dich ans Energieholzteam:<br>Sara Wirthlin / Christian Krichbaum<br><a class="link external" href=mailto:energieholz@rauricawald.ch target=_blank>energieholz@rauricawald.ch</a><br>+41 79 341 22 89</p><p>Bei Fragen zur Applikation wende dich bitte an:<br>Lukas Ziegler<br><a class="link external" href=mailto:lukas.ziegler@rauricawald.ch target=_blank>lukas.ziegler@rauricawald.ch</a><br>+41 61 922 04 62</p></div><div class=block>Version 1.0.0</div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};