
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      sort: null,
      sortorig: null,
      kund: null,
      trans: null,
      polter: null,
      allpolter: null,
      doppel: false
    };
  },
  methods: {
    savels: function () {
      var self = this;
      var db = self.$f7.data.db;
      var app = self.$f7;
      var formData = app.form.convertToData('#lsform');
      var polter = this.polter;
      polter.transtext = app.smartSelect.get('#smarttrans').valueEl.innerText;
      polter.sorttext = app.smartSelect.get('#smartsortiment').valueEl.innerText;
      polter.kundtext = app.smartSelect.get('#smartkunde').valueEl.innerText;
      polter.kund = formData.kund;
      polter.trans = formData.trans;
      polter.sort = formData.sort;
      polter.hacker = localStorage.getItem("api");
      polter.hackertext = localStorage.getItem("HaName");
      var lsid = new Date().getTime();
      polter.lsid = lsid.toString().substr(0, 10);
      polter.fertig = formData.fertig || 0;
      polter.menge = formData.menge;
      polter.kommentar = formData.kommentar;
      if (this.doppel == true) {
        // Doppellieferung
        var polterid = app.smartSelect.get('#smartpolter').$selectEl.val();
        var polter2 = [];
        polter2 = this.allpolter[parseInt(polterid)];
        polter.polter2 = [];
        polter.polter2 = polter2;
        polter.polter2['fertig'] = formData.fertig2 || "0";
        polter.polter2['menge'] = formData.menge2;
        polter.doppel = true;
      }

      // When Sisseln

      if (polter.kund === "21") {
        var swipeToClosePopup = this.$f7.popup.create({
          el: '.popup-about',
          swipeToClose: true
        });
        swipeToClosePopup.open();
        // INIT SCANNER 
        let selectedDeviceId;
        const codeReader = new ZXing.BrowserMultiFormatReader();
        codeReader.getVideoInputDevices().then(videoInputDevices => {
          const sourceSelect = document.getElementById('sourceSelect');
          selectedDeviceId = videoInputDevices[0].deviceId;
          if (videoInputDevices.length >= 1) {
            videoInputDevices.forEach(element => {
              const sourceOption = document.createElement('option');
              sourceOption.text = element.label;
              sourceOption.value = element.deviceId;
              sourceSelect.appendChild(sourceOption);
            });
            sourceSelect.onchange = () => {
              selectedDeviceId = sourceSelect.value;
            };
            const sourceSelectPanel = document.getElementById('sourceSelectPanel');
            sourceSelectPanel.style.display = 'block';
          }
          document.getElementById('startButton').addEventListener('click', () => {
            codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
              if (result) {
                document.getElementById('result').textContent = result.text;

                //result 

                swipeToClosePopup.close();
                polter.lsid = result.text;
                var request = db.transaction('my_lieferschein', "readwrite").objectStore("my_lieferschein").add(polter).onsuccess = function (e) {
                  app.views.main.router.navigate('/ls_view/' + e.target.result + '/');
                };
                codeReader.reset();
              }
              if (err && !(err instanceof ZXing.NotFoundException)) {
                document.getElementById('result').textContent = err;
              }
            });
          });
          document.getElementById('resetButton').addEventListener('click', () => {
            codeReader.reset();
            document.getElementById('result').textContent = '';
          });
        });
        /// END SCANNER 
      } else {
        var request = db.transaction('my_lieferschein', "readwrite").objectStore("my_lieferschein").add(polter).onsuccess = function (e) {
          app.views.main.router.navigate('/ls_view/' + e.target.result + '/');
        };
      }
    },
    initscanner: function () {},
    chg_doppel: function (e) {
      var self = this;
      var allpolter = [];
      var db = self.$f7.data.db;
      var app = self.$f7;
      self.$setState({
        doppel: e.target.checked
      });
      if (e.target.checked == true) {
        var objectStore = db.transaction("my_polter").objectStore("my_polter");
        var data = [];
        var self = this;
        objectStore.openCursor().onsuccess = function (event) {
          var cursor = event.target.result;
          if (cursor) {
            allpolter.push(cursor.value);
            cursor.continue();
          } else {
            self.$setState({
              allpolter: allpolter
            }, () => {
              app.smartSelect.get('#smartpolter').setValue(String(0));
            });
          }
        };
      }
    },
    changekunde: function () {
      var self = this;
      var db = self.$f7.data.db;
      var app = self.$f7;
      var kndid = app.smartSelect.get('#smartkunde').selectEl.value;
      var sortid = app.smartSelect.get('#smartsortiment').selectEl.value;
      console.log('change kunde', sortid);
      var knd = self.kund.filter(function (v, i) {
        return v.KuID === parseInt(kndid);
      });
      var kusort = knd[0].KuSortiment.split(',');

      //KundenSortimente
      var sortfilter = self.sortorig.filter(function (v, i) {
        return kusort.includes(String(v.SoId));
      });

      // Select Sortiment
      var sortselect = sortfilter.filter(function (v, i) {
        return v.SoCode == self.polter.sort;
      });

      // NH/LH Aufteiler WH1
      var wh1 = false;
      if (sortselect.length < 1 && ['A008', 'A009', 'A010', 'A011'].includes(self.polter.sort)) {
        sortselect = sortfilter.filter(function (v, i) {
          return v.SoCode == 'A001';
        });
        if (sortselect.length > 0) {
          wh1 = true;
          self.$setState({
            sort: sortselect
          }, () => {
            app.smartSelect.get('#smartsortiment').setValue(String(sortselect[0].SoCode));
          });
        }
      }
      self.$setState({
        kndid: kndid
      }, () => {
        // Setzen von Transporteur PLV
        if (localStorage.getItem("api") === '07824' && kndid === "0") {
          app.smartSelect.get('#smarttrans').setValue("08012");
        } else {
          app.smartSelect.get('#smarttrans').setValue("07824");
        }
      });
      self.$setState({
        kndid: kndid
      }, () => {
        // Setzen von Transporteur Raurica Holzvermarktung
        if (localStorage.getItem("api") === '08128') {
          app.smartSelect.get('#smarttrans').setValue("08128");
        }
      });
      self.$setState({
        kndid: kndid
      }, () => {
        // Setzen von Transporteur Raurica Hacker
        if (localStorage.getItem("api") === '13106') {
          app.smartSelect.get('#smarttrans').setValue("08128");
          self.$$('#menge').val(80);
        }
      });
      self.$setState({
        sort: sortfilter,
        sort2: sortid
      }, () => {
        if (sortselect.length < 1) {
          app.smartSelect.get('#smartsortiment').setValue(String(sortfilter[0].SoCode));
          app.toast.create({
            text: 'Sortiment ist für die Heizung nicht erlaubt. Bitte wählen!',
            closeTimeout: 3000,
            closeButton: true
          }).open();
        } else if (self.polter.sort != sortid && wh1 == false) {
          app.smartSelect.get('#smartsortiment').setValue(String(self.polter.sort));
          app.toast.create({
            text: 'Sortiment wurde auf Polter-Sortiment zurückgestellt.',
            closeTimeout: 3000,
            closeButton: true
          }).open();
        } else {
          app.smartSelect.get('#smartsortiment').setValue(String(sortselect[0].SoCode));
        }
      });
    }
  },
  on: {
    pageInit: function () {
      var sort = [];
      var kund = [];
      var trans = [];
      var polter = [];
      var self = this;
      var db = self.$f7.data.db;
      var app = self.$f7;

      // Polter laden
      var trx = db.transaction(['my_polter']);
      var mypolter = trx.objectStore("my_polter").get(parseInt(app.views.main.router.currentRoute.params.polter)).onsuccess = function (e) {
        polter = e.target.result;
        self.polter = polter;
        var trx = db.transaction(['t_kunde', 't_sortiment', 't_transporteur']);

        // Kunden laden
        trx.objectStore("t_kunde").index('KuSort').openCursor().onsuccess = function (e) {
          var cursor = e.target.result;
          if (cursor) {
            kund.push(cursor.value);
            cursor.continue();
          }
        };

        // Sortiment laden
        trx.objectStore("t_sortiment").index('SoName').openCursor().onsuccess = function (event) {
          var cursor1 = event.target.result;
          if (cursor1) {
            sort.push(cursor1.value);
            cursor1.continue();
          }
        };

        // Transporteur laden
        trx.objectStore("t_transporteur").index('TrName').openCursor().onsuccess = function (event) {
          var cursor = event.target.result;
          if (cursor) {
            trans.push(cursor.value);
            cursor.continue();
          }
          trx.oncomplete = function () {
            self.$setState({
              trans: trans,
              kund: kund,
              sort: sort,
              sortorig: sort
            }, () => {
              app.smartSelect.get('#smarttrans').setValue(String(trans[0].TrNummer));
              app.smartSelect.get('#smartsortiment').setValue(String(polter.sort));
              app.smartSelect.get('#smartkunde').setValue(String(kund[0].KuID));
            });
          };
        };
      };
    },
    pageAfterIn: function () {},
    pageAfterOut: function () {
      // page has left the view
    }
  },
  id: 'd670cf36fd',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Zurück</span></a></div><div class=title>Lieferschein hinzufügen</div></div></div><div class=page-content><div class=block><form class=list id=lsform><ul><li><a id=smartkunde @change=changekunde class="item-link smart-select smart-select-init" data-close-on-select=true><select name=kund id=kundsearch>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.kund, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<option value=';
          r += c(ctx_2.KuID, ctx_2);
          r += '>';
          r += c(ctx_2.KuName, ctx_2);
          r += '</option>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</select><div class=item-content><div class=item-inner><div class=item-title>Kunde</div></div></div></a></li><li><a id=smarttrans class="item-link smart-select smart-select-init" data-close-on-select=true><select name=trans id=transsearch>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.trans, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<option value=';
          r += c(ctx_2.TrNummer, ctx_2);
          r += '>';
          r += c(ctx_2.TrName, ctx_2);
          r += '</option>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</select><div class=item-content><div class=item-inner><div class=item-title>Transporteur</div></div></div></a></li><li><a id=smartsortiment class="item-link smart-select smart-select-init" data-close-on-select=true><select name=sort id=sortsearch>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.sort, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<option value=';
          r += c(ctx_2.SoCode, ctx_2);
          r += '>';
          r += c(ctx_2.SoName, ctx_2);
          r += '</option>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</select><div class=item-content><div class=item-inner><div class=item-title>Sortiment</div></div></div></a></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Menge</div><div class=item-input-wrap><input type=number name=menge id=menge placeholder=Menge required validate pattern=[0-9]* data-error-message="Nur Zahlen erlaubt"> <span class=input-clear-button></span></div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>Polter abgeschlossen</div><div class=item-after><label class="toggle toggle-init"><input type=checkbox name=fertig value=1><i class=toggle-icon></i></label></div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>Doppellieferung</div><div class=item-after><label class="toggle toggle-init"><input type=checkbox @change=chg_doppel name=doppel value=1><i class=toggle-icon></i></label></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Kommentar</div><div class=item-input-wrap><textarea placeholder=Kommentar id=kommentar name=kommentar></textarea></div></div></div></li></ul>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.doppel, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<br><ul><!-- Doppellieferungen --><li class=item-divider>Doppellieferung</li><li><a id=smartpolter class="item-link smart-select smart-select-init" data-close-on-select=true><select name=polter id=poltersearch>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.allpolter, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<option value=';
              r += c(data_3 && data_3.index, ctx_3);
              r += '>';
              r += c(ctx_3.polter, ctx_3);
              r += ' - ';
              r += c(ctx_3.lieftext, ctx_3);
              r += ' - ';
              r += c(ctx_3.sorttext, ctx_3);
              r += '</option>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</select><div class=item-content><div class=item-inner><div class=item-title>Polter</div></div></div></a></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Menge</div><div class=item-input-wrap><input type=number name=menge2 id=menge2 placeholder=Menge required validate pattern=[0-9]* data-error-message="Nur Zahlen erlaubt"> <span class=input-clear-button></span></div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>Polter abgeschlossen</div><div class=item-after><label class="toggle toggle-init"><input type=checkbox name=fertig2 value=1><i class=toggle-icon></i></label></div></div></div></li></ul>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</form><div class="block block-strong row"><div class=col><a class="button button-fill convert-form-to-data" href=# @click=savels>Lieferschein speichern</a></div></div></div><div class="popup popup-about"><div class=block><p>Scanner für Sisseln Lieferschein</p><p></p><div><a class=button id=startButton>Kamera starten</a> <a class=button id=resetButton>Kamera zurücksetzen</a></div><p></p><div><video id=video style="max-width: 90vw; border: 1px solid gray"></video></div><div id=sourceSelectPanel style=display:none><label for=sourceSelect>Kamera wechseln:</label><div class=""><select id=sourceSelect style=max-width:400px></select></div></div><label>Resultat:</label><pre><code id=result></code></pre><p><a class="link popup-close" href=#>Schliessen</a></p></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    