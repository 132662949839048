import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';



// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

import './jspdf.min.js';
import './html2canvas.min.js';



var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component
  id: 'io.raurica.elieferscheine', // App bundle ID
  name: 'E-Lieferscheine', // App name
  theme: 'ios', // Automatic theme detection
  autoDarkTheme: false,
  template7Pages: true,
  // App root data
  data: function () {
    return {
        db: null,
        request: null,
    };
  },

  // App routes
  routes: routes,

  // Register service worker
  serviceWorker: {
    path: 'service-worker.js',
  },
  version: '0.9.0',
  on: {
    init: function () {
      this.methods.opendb()
        .then(db => this.data.db = db)
      
    },
    serviceWorkerRegisterSuccess: function (registration) {
        registration.addEventListener('updatefound', () => {
            const installingWorker = registration.installing;

            // Watch for changes to the worker's state. Once it is "installed", our cache
            // has been updated with our new files, so we can prompt the user to instantly
            // reload.
            installingWorker.addEventListener('statechange', () => {
              if(installingWorker.state === 'installed') {
                this.dialog.confirm("Eine neue Version ist verfügbar. Soll diese aktualisiert werden?", function () {
                    window.location.reload();
                  });
              }
            });
          });
    }
  },
  methods: {
      opendb: function () {
          return new Promise((resolve, reject) => {
              var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB; // || window.shimIndexedDB;
              let req = indexedDB.open("dbraurica", 2);
              req.onerror = function (evt) {
                  reject(evt.target.error);
                  alert(evt.target.error);
              };
              req.onsuccess = function (evt) {
                  let db = this.result;
                  resolve(db);
              };
              
              req.onupgradeneeded = function(event) {
                var db = event.target.result;
                var objectStore = db.createObjectStore("t_hacker", { keyPath: "HaID" });
                objectStore.createIndex("HaName", "HaName", { unique: false });
                objectStore.createIndex("HaNummer", "HaNummer", { unique: true });

                var objectStore = db.createObjectStore("t_lieferant", { keyPath: "LiID" });
                objectStore.createIndex("LiName", "LiName", { unique: false });
                objectStore.createIndex("LiNummer", "LiNummer", { unique: false });
                objectStore.createIndex("LiBesitzer", "LiBesitzer", { unique: false });

                var objectStore = db.createObjectStore("t_kunde", { keyPath: "KuID" });
                objectStore.createIndex("KuName", "KuName", { unique: false });
                objectStore.createIndex("KuNummer", "KuNummer", { unique: false });
                objectStore.createIndex("KuSort", "KuSort", { unique: false });

                var objectStore = db.createObjectStore("t_sortiment", { keyPath: "SoId" });
                objectStore.createIndex("SoName", "SoName", { unique: false });
                objectStore.createIndex("SoCode", "SoCode", { unique: false });
                objectStore.createIndex("SoSort", "SoSort", { unique: false });

                var objectStore = db.createObjectStore("t_transporteur", { keyPath: "TrID" });
                objectStore.createIndex("TrName", "TrName", { unique: false });
                objectStore.createIndex("TrNummer", "TrNummer", { unique: false });

                var objectStore = db.createObjectStore("t_polter", { keyPath: "keyID", autoIncrement:true });
                objectStore.createIndex("ownerCode", "ownerCode", { unique: false });
                objectStore.createIndex("polterID", "key.polterID", { unique: false });
                objectStore.createIndex("sortCode", "sortCode", { unique: false });

                var objectStore = db.createObjectStore("my_polter", { keyPath: "keyID", autoIncrement:true });
                var objectStore = db.createObjectStore("my_lieferschein", { keyPath: "keyID2", autoIncrement:true });
              };
              
          });
      },
      
      indexdb: function () {
        const dbName = "dbraurica";
        var request = window.indexedDB.open(dbName, 2);
        var db;
        var self = this;
        
        request.onsuccess = function (event) {
          db = event.target.result;
        }

        request.onerror = function(event) {
          alert("Warum haben Sie meiner Webapp nicht erlaubt IndexedDB zu verwenden?!");
        };
        request.onupgradeneeded = function(event) {
          var db = event.target.result;
          var objectStore = db.createObjectStore("t_hacker", { keyPath: "HaID" });
          objectStore.createIndex("HaName", "HaName", { unique: false });
          objectStore.createIndex("HaNummer", "HaNummer", { unique: true });

          var objectStore = db.createObjectStore("t_lieferant", { keyPath: "LiID" });
          objectStore.createIndex("LiName", "LiName", { unique: false });
          objectStore.createIndex("LiNummer", "LiNummer", { unique: false });
          objectStore.createIndex("LiBesitzer", "LiBesitzer", { unique: false });

          var objectStore = db.createObjectStore("t_kunde", { keyPath: "KuID" });
          objectStore.createIndex("KuName", "KuName", { unique: false });
          objectStore.createIndex("KuNummer", "KuNummer", { unique: false });
          objectStore.createIndex("KuSort", "KuSort", { unique: false });

          var objectStore = db.createObjectStore("t_sortiment", { keyPath: "SoId" });
          objectStore.createIndex("SoName", "SoName", { unique: false });
          objectStore.createIndex("SoCode", "SoCode", { unique: false });
          objectStore.createIndex("SoSort", "SoSort", { unique: false });

          var objectStore = db.createObjectStore("t_transporteur", { keyPath: "TrID" });
          objectStore.createIndex("TrName", "TrName", { unique: false });
          objectStore.createIndex("TrNummer", "TrNummer", { unique: false });

          var objectStore = db.createObjectStore("t_polter", { keyPath: "keyID", autoIncrement:true });
          objectStore.createIndex("ownerCode", "ownerCode", { unique: false });
          objectStore.createIndex("polterID", "key.polterID", { unique: false });
          objectStore.createIndex("sortCode", "sortCode", { unique: false });

          var objectStore = db.createObjectStore("my_polter", { keyPath: "keyID", autoIncrement:true });
          var objectStore = db.createObjectStore("my_lieferschein", { keyPath: "keyID2", autoIncrement:true });
        };
    },
  },
});


