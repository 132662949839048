
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      datas: null,
      ha: {
        haName: 'Hacker'
      }
    };
  },
  on: {
    pageInit: function () {
      const dbName = "dbraurica";
      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB; // || window.shimIndexedDB;
      var request = indexedDB.open(dbName, 2);
      var db;
      var self = this;
      request.onsuccess = function (event) {
        db = event.target.result;
        var objectStore = db.transaction("my_polter").objectStore("my_polter");
        var data = [];
        objectStore.openCursor().onsuccess = function (event) {
          var cursor = event.target.result;
          if (cursor) {
            data.push(cursor.value);
            cursor.continue();
          } else {
            self.$setState({
              datas: data
            });
          }
        };
        var os = db.transaction("t_hacker").objectStore("t_hacker").openCursor().onsuccess = function (event) {
          var cursor = event.target.result;
          if (cursor) {
            self.$setState({
              ha: cursor.value
            });
          }
        };
      };
    },
    swipeoutDeleted: function (e) {
      const dbName = "dbraurica";
      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB; // || window.shimIndexedDB;
      var request = indexedDB.open(dbName, 2);
      var db;
      var self = this;
      request.onsuccess = function (event) {
        db = event.target.result;
        var trx = db.transaction(['my_polter'], "readwrite");
        var mypls = trx.objectStore("my_polter");
        var objectStoreRequest = mypls.delete(parseInt(e.target.dataset.id));
        objectStoreRequest.onerror = function (event) {
          console.log(event);
        };
      };
    }
  },
  id: '252b24f1c3',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class=title>Polter</div></div></div><!-- Scrollable page content--><div class=page-content><div class=block><div class=block-title>';
      r += c(ctx_1.ha.HaName, ctx_1);
      r += '</div><p>Bearbeitete Polter</p></div><div class="list media-list"><ul>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.datas, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.datas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=swipeout data-id=';
              r += c(ctx_3.keyID, ctx_3);
              r += '><div class=swipeout-content><a href=/ls_add/';
              r += c(ctx_3.keyID, ctx_3);
              r += '/ class="item-link item-content"><div class=item-inner><div class=item-title>';
              r += c(ctx_3.polter, ctx_3);
              r += ' ';
              r += c(ctx_3.los, ctx_3);
              r += '</div><div class=item-subtitle>';
              r += c(ctx_3.lieftext, ctx_3);
              r += '</div><div class=item-subtitle>';
              r += c(ctx_3.sorttext, ctx_3);
              r += '</div><div class=item-subtitle>';
              r += c(ctx_3.zuab, ctx_3);
              r += '</div></div></a></div><div class=swipeout-actions-right><!-- Swipeout actions links/buttons --> <a href=# class=swipeout-delete data-confirm="Polter wirklich löschen?" data-confirm-title=Löschen?>Löschen</a></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=item-content><div class=item-inner><div class=item-title>keine Polter gefunden</div></div></div></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div id=fab_add_pol class="fab fab-right-bottom"><a href=/polter_add/ ><i class="icon f7-icons">plus</i></a></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    