
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      var FileSaver = require('file-saver');
export default {
  data: function () {
    return {
      datas: null,
      ha: {
        haName: 'Hacker'
      }
    };
  },
  methods: {
    send_toraurica: function () {
      this.$f7.dialog.alert('Funktion noch nicht verfügbar');
    },
    delete_all: function () {
      var self = this;
      self.$f7.dialog.confirm('Wirklich ALLE Lieferscheine löschen?', function () {
        var db = self.$f7.data.db;
        var trx = db.transaction(['my_lieferschein'], "readwrite");
        var myls = trx.objectStore('my_lieferschein');
        myls.clear();
        self.$f7.toast.create({
          text: 'Lieferscheine gelöscht',
          closeTimeout: 3000,
          closeButton: true
        }).open();
        self.$setState({
          datas: []
        });
      });
    },
    create_allpdf() {
      var self = this;
      var printSettings = {
        width: 63,
        height: 40,
        margin: 5
      };
      var position = {
        x: printSettings.margin,
        y: printSettings.margin
      };
      var doc = new jsPDF({
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait'
      });
      doc.setFontSize(12);
      doc.text("Lieferscheine", position.x + 5, position.y + 10);
      doc.setFontSize(10);
      for (var id in self.datas) {
        self.ls = self.datas[id];
        doc.setFont('helvetica');
        if (self.ls.doppel == true) {
          doc.text("Doppellieferung", position.x + 5, position.y + 15);
        }
        var d = new Date(self.ls.lsid * 1000);
        var datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
        doc.text('Nummer:', position.x + 5, position.y + 25);
        doc.text('' + self.ls.lsid + ' / ' + datestring, position.x + 40, position.y + 25);
        doc.text('Heizung:', position.x + 5, position.y + 30);
        doc.text('' + self.ls.kundtext + ' (' + self.ls.kund + ')', position.x + 40, position.y + 30);
        doc.text('Hacker:', position.x + 5, position.y + 35);
        doc.text('' + self.ls.hackertext + ' (' + self.ls.hacker + ')', position.x + 40, position.y + 35);
        doc.text('Transporteur:', position.x + 5, position.y + 40);
        doc.text('' + self.ls.transtext + ' (' + self.ls.trans + ')', position.x + 40, position.y + 40);
        doc.text('Sortiment:', position.x + 5, position.y + 45);
        doc.text('' + self.ls.sorttext + ' (' + self.ls.sort + ')', position.x + 40, position.y + 45);
        doc.text('Lieferant:', position.x + 5, position.y + 50);
        doc.text('' + self.ls.lieftext + ' (' + self.ls.lief + ')', position.x + 40, position.y + 50);
        doc.text('Polter/Los:', position.x + 5, position.y + 55);
        doc.text('' + self.ls.polter + '/' + self.ls.los, position.x + 40, position.y + 55);
        doc.text('Zu-/Abschlag:', position.x + 5, position.y + 60);
        doc.text('' + self.ls.zuab, position.x + 40, position.y + 60);
        doc.text('Abgeschlossen:', position.x + 5, position.y + 65);
        doc.text('' + self.ls.fertig, position.x + 40, position.y + 65);
        doc.text('Menge:', position.x + 5, position.y + 70);
        doc.text('' + self.ls.menge, position.x + 40, position.y + 70);
        doc.text('Kommentar:', position.x + 5, position.y + 75);
        doc.text('' + self.ls.kommentar, position.x + 40, position.y + 75);
        var pos2 = 0;
        if (self.ls.doppel == true) {
          doc.text("------------------------------------------------------------------------------", position.x + 5, position.y + 80);
          doc.text('Lieferant:', position.x + 5, position.y + 85);
          doc.text('' + self.ls.polter2.lieftext + ' (' + self.ls.polter2.lief + ')', position.x + 40, position.y + 85);
          doc.text('Polter/Los:', position.x + 5, position.y + 90);
          doc.text('' + self.ls.polter2.polter + '/' + self.ls.polter2.los, position.x + 40, position.y + 90);
          doc.text('Zu-/Abschlag:', position.x + 5, position.y + 95);
          doc.text('' + self.ls.polter2.zuab, position.x + 40, position.y + 95);
          doc.text('Abgeschlossen:', position.x + 5, position.y + 100);
          doc.text('' + self.ls.polter2.fertig, position.x + 40, position.y + 100);
          doc.text('Menge:', position.x + 5, position.y + 105);
          doc.text('' + self.ls.polter2.menge, position.x + 40, position.y + 105);
          pos2 = 30;
        }

        /*var imgData = self.$$("#QR_" + self.ls.keyID2 +" > img").prop('currentSrc');
        if (imgData)
          doc.addImage(imgData, 'PNG', position.x+140, position.y+20, 50, 50);
        */
        var qrcodeid = document.getElementById("QR_" + self.ls.keyID2);
        var imgData = qrcodeid.getElementsByTagName("canvas")[0].toDataURL("image/png");
        if (imgData) doc.addImage(imgData, 'PNG', position.x + 140, position.y + 20, 50, 50);
        position.y = position.y + 70 + pos2 + 10;
        doc.text("------------------------------------------------------------------------------------------------------------------------------------------------------------", position.x + 5, position.y + 0);
        if (position.y >= 200) {
          doc.addPage();
          position.y = 5;
        }
      }

      //doc.save('LS'+self.ls.lsid+'.pdf');
      var blob = doc.output('blob');
      FileSaver.saveAs(blob, 'Lieferscheine.pdf');
    }
  },
  on: {
    pageInit: function () {
      var data = [];
      const dbName = "dbraurica";
      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB; // || window.shimIndexedDB;
      var request = indexedDB.open(dbName, 2);
      var db;
      var self = this;
      request.onsuccess = function (event) {
        db = event.target.result;
        var objectStore = db.transaction("my_lieferschein").objectStore("my_lieferschein");
        objectStore.openCursor().onsuccess = function (event) {
          var cursor = event.target.result;
          if (cursor) {
            data.push(cursor.value);
            cursor.continue();
          } else {
            self.$setState({
              datas: data
            }, () => {
              for (var id in self.datas) {
                var ls = self.datas[id];
                var qrtext = ls.lief + ';' + ls.hacker + ';' + ls.trans + ';' + ls.kund + ';' + ls.sort + ';' + ls.polter + ';' + ls.zuab + ';' + ls.fertig + ';' + ls.los + ';' + ls.menge;
                if (ls.doppel == true) {
                  qrtext = qrtext + ':' + ls.polter2.lief + ';' + ls.hacker + ';' + ls.trans + ';' + ls.kund + ';' + ls.polter2.sort + ';' + ls.polter2.polter + ';' + ls.polter2.zuab + ';' + ls.polter2.fertig + ';' + ls.polter2.los + ';' + ls.polter2.menge;
                }
                if (parseInt(ls.kund) > 2) {
                  // Alles was nicht HKW I und II ist, füge den Lieferschein am Ende hinzu
                  qrtext = qrtext + '$$' + ls.lsid;
                }
                var qrcode = new QRCode("QR_" + ls.keyID2, {
                  text: qrtext,
                  width: 250,
                  height: 250,
                  colorDark: "#000000",
                  colorLight: "#ffffff",
                  correctLevel: QRCode.CorrectLevel.H
                });
              }
            });
          }
        };
        var os = db.transaction("t_hacker").objectStore("t_hacker").openCursor().onsuccess = function (event) {
          var cursor = event.target.result;
          if (cursor) {
            self.$setState({
              ha: cursor.value
            });
          }
        };
      };
    },
    swipeoutDeleted: function (e) {
      var self = this;
      var db = self.$f7.data.db;
      var app = self.$f7;
      var trx = db.transaction(['my_lieferschein'], "readwrite");
      var mypls = trx.objectStore("my_lieferschein");
      var objectStoreRequest = mypls.delete(parseInt(e.target.dataset.id));
      objectStoreRequest.onerror = function (event) {
        console.log(event);
      };
    }
  },
  id: '53613fe1ee',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=lieferscheine><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=title>Lieferscheine</div><div class=right><div style="margin-left: auto" class="menu-item menu-item-dropdown"><div class="menu-item-content icon-only"><div class=menu-item-content><i class=f7-icons>share</i></div></div><div class="menu-dropdown menu-dropdown-right"><div class=menu-dropdown-content><!--<a href="#" @click="send_toraurica()" class="menu-dropdown-link ">\r\n                    <span>An Raurica senden</span>\r\n                    <i class="f7-icons margin-left">at_circle_fill</i>\r\n                  </a>--> <a href=# @click=create_allpdf() class="menu-dropdown-link menu-close" style="margin-left: 0;"><span>PDF</span> <i class="f7-icons margin-left">arrow_down_doc_fill</i> </a><a href=# @click=delete_all() class="menu-dropdown-link menu-close" style="margin-left: 0;"><span>alle löschen</span> <i class="f7-icons margin-left">bin_xmark_fill</i></a></div></div></div></div></div></div><div class=page-content><div class=block><div class=block-title>Lieferscheine</div></div><div class="list media-list"><ul>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.datas, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.datas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=swipeout data-id=';
              r += c(ctx_3.keyID2, ctx_3);
              r += '><div class=swipeout-content><a href=/ls_view/';
              r += c(ctx_3.keyID2, ctx_3);
              r += '/ class="item-link item-content"><div class=item-media style=display:none><div id=QR_';
              r += c(ctx_3.keyID2, ctx_3);
              r += '></div></div><div class=item-inner><div class=item-row><div class=item-title>';
              r += c(ctx_3.polter, ctx_3);
              r += ' ';
              r += c(ctx_3.los, ctx_3);
              r += '</div>';
              r += Template7Helpers.if.call(ctx_3, ctx_3.doppel, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=item-after>Doppellieferung</div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-row><div class=item-subtitle>';
              r += c(ctx_3.kundtext, ctx_3);
              r += '</div><div class=item-after>';
              r += c(ctx_3.menge, ctx_3);
              r += ' srm</div></div><div class=item-row><div class=item-subtitle>';
              r += c(ctx_3.lieftext, ctx_3);
              r += '</div><div class=item-after>';
              r += c(ctx_3.zuab, ctx_3);
              r += ' - ';
              r += c(ctx_3.fertig, ctx_3);
              r += '</div></div><div class=item-row><div class=item-subtitle>';
              r += c(ctx_3.sorttext, ctx_3);
              r += '</div><div class=item-text>';
              r += c(ctx_3.lsid, ctx_3);
              r += '</div></div></div></a></div><div class=swipeout-actions-right><!-- Swipeout actions links/buttons --><!--<a href="#" class="swipeout-close">Action 1</a>--> <a href=# class=swipeout-delete data-confirm="Lieferschein wirklich löschen?" data-confirm-title=Löschen?>Löschen</a></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=item-content><div class=item-inner><div class=item-title>keine Lieferscheine gefunden</div></div></div></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    